import React from "react";
import {Button, Group, Header, Title} from "@mantine/core";
import Container from "./components/Container";
import {useNavigate} from "react-router-dom";
import {useCookieState} from "ahooks";
import axios from "axios";
import {useLocalStorage} from "@mantine/hooks";
import LoginRoute from "./routes/LoginRoute";
import {basePath} from "./utils";

const MainContainer = (props) => {

  let navigate = useNavigate();

  const [userData, setUserData] = useLocalStorage(
      {key: 'wishListData', defaultValue: {loggedIn: false, admin: false, userId: -1}})

  const [token, setToken] = useCookieState('token');

  const logout = () => {
    axios.get(`${basePath}/logout/`, {withCredentials: true}).then(r => {
      navigate("/", {replace: true})
      setToken(undefined)
      setUserData({loggedIn: false, admin: false})
    }).catch(reason => {
      navigate("/", {replace: true})
      setToken(undefined)
      setUserData({loggedIn: false, admin: false})
    })
  }

  if (!userData.loggedIn) {
    return <LoginRoute/>
  }

  return (
      // <AppShell
      //     padding="md"
      //     // navbar={<Navbar width={{ base: 300 }} height={500} p="xs">{/* Navbar content */}</Navbar>}
      //     header={<Header height={60} p="xs" className={"flex justify-between"}>
      //       <Title order={1} onClick={() => {  navigate("/item") }} className={"cursor-pointer"}>Wunschliste Einschulung Paul</Title>
      //       <div className={"flex gap-2"}>
      //         {userData.admin && <Button color={"green"
      //             + ""} onClick={() => {  navigate("/item/create") }}>neuen Eintrag erstellen</Button>}
      //         <Button color={"yellow"} onClick={()=> {navigate('/bid')}}>meine Geschenke</Button>
      //         <Button color={"red"} onClick={logout}>Logout</Button>
      //       </div>
      //     </Header>}
      //     styles={(theme) => ({
      //       main: {
      //         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
      //       },
      //       body: {height: '100vh'}
      //     })}
      //     className={"h-full flex flex-col"}
      // >
      //   <Container routes={props.routes}/>
      // </AppShell>

      <div className={"flex flex-col h-screen"}>
        <Header
            // height={60}
            // fixed
            p="xs" className={"flex justify-between"}>
          <Title order={3} onClick={() => {
            navigate("/item")
          }} className={"cursor-pointer basis-1/2"}>Wunschliste Einschulung Lotti</Title>
          <Group className={"flex gap-2"}>
            {userData.admin && <Button color={"green"
                + ""} onClick={() => {
              navigate("/item/create")
            }}>neuen Eintrag erstellen</Button>}
            <Button  variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }} onClick={() => {
              navigate('/bid')
            }}>meine Geschenke</Button>
            <Button  color={"red"} onClick={logout}>Logout</Button>
          </Group>
        </Header>
        <Container routes={props.routes}/>
      </div>


)
  ;
};

MainContainer.propTypes = {};

export default MainContainer;