import React, {useContext} from "react";
import {bids} from "../api/API";
import BidContainer from "../components/BidContainer";
import {UserDataContext} from "../App";
import {Badge, Container, List, Paper, Stack, Text, ThemeIcon, Title} from "@mantine/core";
import {CircleCheck, Trash} from "tabler-icons-react";

const BidListRoute = (props) => {

  const {data, isLoading} = bids.use()

  if (isLoading){
    return <div>loading!</div>
  }

  // TODO : add item to bid to percentage

  return (
      <div className={"h-full bg-slate-100 overflow-x-hidden overflow-y-auto"}>


        <Container className={"bg-white h-full"}>

          <Title order={2} className={"!mb-4 pt-4"}>Meine Geschenke :</Title>

          {/*<List*/}
          {/*    spacing="xs"*/}
          {/*    size="sm"*/}
          {/*    center*/}
          {/*    // className={"mt-8"}*/}
          {/*    icon={*/}
          {/*      <ThemeIcon color="teal" size={24} radius="xl">*/}
          {/*        <CircleCheck size={16} />*/}
          {/*      </ThemeIcon>*/}
          {/*    }*/}
          {/*    classNames={{*/}
          {/*      itemWrapper: 'w-full',*/}
          {/*      item: 'w-full',*/}
          {/*    }}*/}
          {/*>*/}

          <Stack>

            {data.map((bid) => {
              return <BidContainer key={bid.id} id={bid.id} amount={bid.amount} itemID={bid.itemId}
                                   userId={bid.userId}
                                   itemName={bid.itemName} userName={bid.userName}/>
              // return <div key={bid.id} className={"w-full flex rounded-lg shadow-lg transition-all hover:shadow-xl"}>
              //
              //   <div className={"rounded-l-lg basis-1/6 bg-green-100 flex justify-center items-center align-middle"}>
              //
              //     <div className={"flex gap-1 basis-full justify-center"}>
              //
              //       <Badge size="lg" variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}> {bid.amount} €</Badge>
              //
              //       {/*<div className={"basis-1/2 flex justify-center"}><ThemeIcon color="teal" size={24} radius="xl" >*/}
              //       {/*  <CircleCheck size={16}/>*/}
              //       {/*</ThemeIcon></div>*/}
              //
              //       {/*<div className={"basis-1/2 grow"}>*/}
              //       {/*  {bid.amount} €*/}
              //       {/*</div>*/}
              //
              //     </div>
              //
              //
              //
              //   </div>
              //
              //   <BidContainer key={bid.id} id={bid.id} amount={bid.amount} itemID={bid.itemId}
              //                                 userId={bid.userId}
              //                                 itemName={bid.itemName} userName={bid.userName}/>
              //   <div className={"group rounded-r-lg basis-1/12 bg-red-100 flex justify-center items-center align-middle cursor-pointer"}>
              //
              //     <ThemeIcon color="red" size={24} radius="xl" className={"group-hover:scale-125 transition-all"}>
              //       <Trash size={16} />
              //     </ThemeIcon>
              //
              //   </div>
              //
              //
              // </div>
            })}
          </Stack>
          {/*</List>*/}


          {/*<Stack justify={"center"} className={"mt-4"}>*/}
          {/*  {data.map((bid) => {*/}
          {/*    return <BidContainer key={bid.id} id={bid.id} amount={bid.amount} itemID={bid.itemId} userId={bid.userId}*/}
          {/*                         itemName={bid.itemName} userName={bid.userName}/>*/}
          {/*  })}*/}
          {/*</Stack>*/}


        </Container>


      </div>
  );
};

BidListRoute.propTypes = {};

export default BidListRoute;