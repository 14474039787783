import React, {useRef, useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useCookieState} from "ahooks";
import {useLocalStorage} from "@mantine/hooks";
import {Center} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {Cross, X} from "tabler-icons-react";
import {basePath, devmode} from "../utils";

const LoginRoute = (props) => {

  const [userData, setUserData] = useLocalStorage(
      {key: 'wishListData', defaultValue: {loggedIn: false, admin: false, userId: -1}})

  let [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();

  const [pinCode, setPinCode] = useState(['', '', '', '']);

  const linkId = searchParams.get("link_id");

  const input1 = useRef()
  const input2 = useRef()
  const input3 = useRef()
  const input4 = useRef()

  const [token, setToken] = useCookieState('token');

  const handleFocus = (event) => event.target.select()
  // const handleFocus = (id) => {
  //   for (let i = id; i < 4; i++) {
  //     let temp = [...pinCode]
  //     temp[i] = ''
  //     setPinCode(temp)
  //   }
  //
  // }

  const finishInput = (input) => {

    axios.post(`${basePath}/login/`, {linkId, passcode: input}, {withCredentials: true}).then(r => {
      setUserData({loggedIn: true, admin: r.data.admin, userId: r.data.userId})
      navigate("/item", {replace: true})
    }).catch(reason => {
      input1.current.focus()
      showNotification({
        id: 'hello-there',
        disallowClose: false,
        // onClose: () => console.log('unmounted'),
        // onOpen: () => console.log('mounted'),
        autoClose: 5000,
        title: "Login fehlgeschlagen!",
        message: reason.message,
        color: 'red',
        icon: <X />,
        // className: 'my-notification-class',
        // style: { backgroundColor: 'red' },
        // sx: { backgroundColor: 'red' },
        loading: false,
      });
    })

  }

  if (linkId === null && devmode) {
    return (
        <div>
          <div>Nutze den link in der email um dich einzuloggen.</div>
          <div className={"flex"}><Link className={"bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"} to="/login?link_id=1234">testlogin</Link></div>
          <div className={"flex"}><Link
              className={"bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"}
              to="/login?link_id=89764589634">login admin with 0000</Link></div>
          <div className={"flex"}><Link
              className={"bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"}
              to="/login?link_id=987237863876">login user 1 with 1234</Link></div>
        </div>

    );
  }

  if (linkId === null) {
    return (
        <div>
          <div>Nutze den link in der email um dich einzuloggen.</div>
        </div>

    );
  }

  return (
      <Center className={"h-screen"}>


        <div className="bg-white rounded-lg shadow p-4">
          <div className="font-thin px-2 pb-4 text-lg">Gib deine PIN ein.</div>
          <div className="flex">
            {/*<template x-for="(l,i) in pinlength" :key="`codefield_${i}`">*/}
            <input ref={input1}
                   autoFocus={true}
                   value={pinCode[0]}
                   onInput={() => {
                     input2.current.focus()
                   }}
                   onChange={(e) => {
                     let temp = [...pinCode]
                     temp[0] = e.target.value
                     setPinCode(temp)

                   }
                   }
                   onFocus={handleFocus}
                   className="h-16 w-12 border mx-2 rounded-lg flex items-center text-center font-thin text-3xl"
                   maxLength="1" max="9" min="0" inputMode="decimal"></input>
            <input ref={input2}
                   value={pinCode[1]}
                   onInput={() => {
                     input3.current.focus()
                   }}
                   onChange={(e) => {
                     let temp = [...pinCode]
                     temp[1] = e.target.value
                     setPinCode(temp)

                   }
                   }
                   onFocus={handleFocus}
                   className="h-16 w-12 border mx-2 rounded-lg flex items-center text-center font-thin text-3xl"
                   maxLength="1" max="9" min="0" inputMode="decimal"></input>
            <input ref={input3}
                   value={pinCode[2]}
                   onInput={() => {
                     input4.current.focus()
                   }}
                   onChange={(e) => {
                     let temp = [...pinCode]
                     temp[2] = e.target.value
                     setPinCode(temp)

                   }
                   }
                   onFocus={handleFocus}
                   className="h-16 w-12 border mx-2 rounded-lg flex items-center text-center font-thin text-3xl"
                   maxLength="1" max="9" min="0" inputMode="decimal"></input>
            <input ref={input4}
                   value={pinCode[3]}
                   onInput={(e) => {
                     let temp = [...pinCode]
                     temp[3] = e.target.value
                     finishInput(temp)
                   }}
                   onChange={(e) => {
                     let temp = [...pinCode]
                     temp[3] = e.target.value
                     setPinCode(temp)

                   }
                   }
                   onFocus={handleFocus}
                   className="h-16 w-12 border mx-2 rounded-lg flex items-center text-center font-thin text-3xl"
                   maxLength="1" max="9" min="0" inputMode="decimal"></input>
            {/*</template>*/}
          </div>
        </div>


        {/*<div className={"flex "}>*/}

        {/*  <div>pin code</div>*/}
        {/*  <div>*/}
        {/*    <input ref={input1}*/}
        {/*           type="text"*/}
        {/*           maxLength={1}*/}
        {/*           minLength={1}*/}
        {/*           className={"w-12"}*/}
        {/*           onFocus={handleFocus}*/}
        {/*           onChange={(e) => {*/}
        {/*             let temp = [...pinCode]*/}
        {/*             temp[0] = e.target.value*/}
        {/*             setPinCode(temp)*/}
        {/*             input2.current.focus()*/}
        {/*           }}/>*/}

        {/*    <input ref={input2}*/}
        {/*           type="text"*/}
        {/*           maxLength={1}*/}
        {/*           minLength={1}*/}
        {/*           className={"w-12"}*/}
        {/*           onFocus={handleFocus}*/}
        {/*           onChange={(e) => {*/}
        {/*             let temp = [...pinCode]*/}
        {/*             temp[1] = e.target.value*/}
        {/*             setPinCode(temp)*/}
        {/*             input3.current.focus()*/}
        {/*           }}/>*/}

        {/*    <input ref={input3}*/}
        {/*           type="text"*/}
        {/*           maxLength={1}*/}
        {/*           minLength={1}*/}
        {/*           className={"w-12"}*/}
        {/*           onFocus={handleFocus}*/}
        {/*           onChange={(e) => {*/}
        {/*             let temp = [...pinCode]*/}
        {/*             temp[2] = e.target.value*/}
        {/*             setPinCode(temp)*/}
        {/*             input4.current.focus()*/}
        {/*           }}/>*/}

        {/*    <input ref={input4}*/}
        {/*           type="text"*/}
        {/*           maxLength={1}*/}
        {/*           minLength={1}*/}
        {/*           className={"w-12"}*/}
        {/*           onFocus={handleFocus}*/}
        {/*           onChange={(e) => {*/}
        {/*             let temp = [...pinCode]*/}
        {/*             temp[3] = e.target.value*/}
        {/*             setPinCode(temp)*/}
        {/*             input1.current.focus()*/}

        {/*             finishInput(temp)*/}

        {/*           }}/>*/}
        {/*  </div>*/}

        {/*</div>*/}


      </Center>
  );
};

LoginRoute.propTypes = {};

export default LoginRoute;