import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {items} from "../api/API";
import ItemForm from "../components/ItemForm";

const ItemEditRoute = (props) => {

  let {itemId} = useParams();
  let navigate = useNavigate();
  const {data, isLoading} = items.single(itemId)

  if (isLoading) {
    return <div> loading!!! </div>
  }

  return (
      <ItemForm id={itemId} name={data.name} price={data.price} description={data.description} pictures={data.pictures}
                public={data.public}/>
  )
};

ItemEditRoute.propTypes = {};

export default ItemEditRoute;