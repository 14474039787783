import React, {useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {items} from "../api/API";
import {
    Badge,
    Button,
    Center,
    Container,
    Grid,
    Group,
    Image,
    Paper,
    RingProgress,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {useModals} from "@mantine/modals";
import axios from "axios";
import {RichTextEditor} from "@mantine/rte";
import {UserDataContext} from "../App";
import BidContainer from "../components/BidContainer";
import BidCreateRoute from "./BidCreateRoute";
import {ArrowBackUp, ThumbUp} from "tabler-icons-react";
import {basePath} from "../utils";

const ItemRoute = (props) => {

    let {itemId} = useParams();
    let navigate = useNavigate();
    const {data, isLoading} = items.single(itemId)

    const userData = useContext(UserDataContext)

    const modals = useModals();

    if (isLoading) {
        return (<div>loading...</div>)
    }

    let filled = false
    if (data.remaining === 0) {
        filled = true
    }

    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: 'Delete Item',
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete this Item?
                </Text>
            ),
            labels: {confirm: 'Delete Item', cancel: "No don't delete it"},
            confirmProps: {color: 'red'},
            trapFocus: false,
            closeOnClickOutside: true,
            overlayBlur: 2,
            onCancel: () => console.log('Cancel'),
            onConfirm: () => deleteItem(),
        });

    const deleteItem = () => {
        // TODO : first modal -> then delete
        axios.delete(`${basePath}/items/${itemId}`, {withCredentials: true}).then(
            navigate("/item", {replace: true})
        )
    }

    const openBidModal = () => {
        modals.openModal({
            id: 'bidModal',
            centered: true,
            title: 'Mit wieviel möchtest du dich beteiligen?',
            radius: 'lg',
            children: (
                <>
                    {/*<TextInput label="Your email" placeholder="Your email" data-autofocus />*/}

                    <BidCreateRoute itemId={itemId}/>

                </>
            ),
        });
    };

    const openImageModal = (image) => {
        modals.openModal({
            id: 'imageModal',
            centered: true,
            size: "70%",
            // title: 'Mit wieviel möchtest du dich beteiligen?',
            children: (
                <>
                    {/*<TextInput label="Your email" placeholder="Your email" data-autofocus />*/}

                    <Image src={`${basePath}/upload/${image}`} fit={"cover"}></Image>

                </>
            ),
        });
    };

    return (
        <div className={"w-full flex h-full overflow-x-hidden overflow-y-auto  bg-slate-100 "}>
            <Container fluid
                       className={"!basis-full lg:!basis-2/3 xl:!basis-1/2 h-full "}>
                <Paper shadow={"md"} className={"!mt-2"} p={"sm"}>
                    <Group>

                        <Button leftIcon={<ArrowBackUp size={14}/>} compact={true} onClick={() => {
                            // navigate("/item")

                            navigate(-1)

                        }}> zurück </Button>
                        {userData.admin && <Button color={'yellow'} onClick={() => {
                            navigate(`/item/${itemId}/edit`)
                        }}>Eintrag ändern</Button>}
                        {userData.admin && <Button color={"red"} onClick={openDeleteModal}>Eintrag löschen</Button>}
                    </Group>


                    <h1 className={"my-8"}>{data.name}</h1>
                    {/*<div dangerouslySetInnerHTML={{__html:data.description}}></div>*/}

                    <RichTextEditor className={"my-8"} readOnly value={data.description} onChange={() => {
                    }}/>

                    <Grid className={"my-8"} position={"apart"} grow>
                        {data.pictures.map(picture => <Grid.Col md={6} lg={2} key={picture.id}><Center key={picture.id}>
                            <Image width={300}
                                   height={300}
                                   radius={"lg"}
                                   src={`${basePath}/upload/${picture.id}`}
                                   onClick={() => {
                                       openImageModal(
                                           picture.id)
                                   }}
                                   className={"rounded-2xl shadow-md cursor-pointer hover:shadow-xl lg:hover:scale-110 transition-all"}
                            /></Center></Grid.Col>)}
                    </Grid>

                    <Group className={"my-8"} position={"apart"}>

                        <Button disabled={data.bids.filter((bid) => bid.userId === userData.userId).length > 0}
                                onClick={openBidModal}
                                leftIcon={<ThumbUp size={14}/>}
                                size={"lg"}
                                variant="gradient" gradient={{from: 'teal', to: 'lime', deg: 105}}
                                className={"text-lg"}>Ich möchte mich beteiligen!</Button>

                        <Group><Badge color={filled ? "gray" : "green"} variant="light" size="xl">
                            {data.remaining}€
                        </Badge>
                            <RingProgress
                                size={80}
                                sections={[{value: 100 - (data.remaining / data.price * 100), color: 'green'}]}
                                label={
                                    // filled && <Check size={12} />
                                    <Text color="blue" weight={700} align="center" size={"xs"}>
                                        {Math.trunc(100 - (data.remaining / data.price * 100))}%
                                    </Text>
                                }
                            /></Group>


                    </Group>


                    {/*<Link to={`/item/${data.id}/create-bid`}*/}
                    {/*      className={" bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"}*/}
                    {/*>create bid*/}
                    {/*</Link>*/}


                    <div className={"p-2 m-4 "}>
                        <Container className={"bg-white h-full"} p={"lg"}>
                            <Title order={2} className={"!mb-4 pt-4"}>Anteile : </Title>

                            <Stack>
                                {data.bids.filter((bid) => userData.admin || bid.id_User === userData.userId).map((bid) => {
                                    return <BidContainer key={bid.id} id={bid.id} amount={bid.amount}
                                                         itemID={bid.itemId}
                                                         userId={bid.id_User}
                                                         itemName={bid.itemName} userName={bid.userName}
                                                         standalone={false}/>
                                })}
                            </Stack>
                        </Container>
                    </div>
                </Paper>

            </Container></div>
    );
};

ItemRoute.propTypes = {};

export default ItemRoute;