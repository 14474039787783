import './App.css';
import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter} from "react-router-dom";
import {ReactQueryDevtools} from "react-query/devtools";
import React from "react";
import {createRoutes} from "./routes";
import {MantineProvider} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";
import {NotificationsProvider} from "@mantine/notifications";
import MainContainer from "./MainContainer";
import {useLocalStorage} from "@mantine/hooks";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      // staleTime: 60 * 5 * 1000
    },
  },
})

const queryClient = new QueryClient({queryCache})

const routes = createRoutes();

const RoutesContext = React.createContext(routes);
export const UserDataContext = React.createContext({});

function App() {

  const [userData, setUserData] = useLocalStorage(
      {key: 'wishListData', defaultValue: {loggedIn: false, admin: false, userId: -1}})

  return (
      <MantineProvider //theme={{fontFamily: 'Open Sans'}}
          // theme={{ colorScheme: 'dark' }}
          emotionOptions={{key: 'mantine', prepend: false}}
          withGlobalStyles withNormalizeCSS
      >

        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ModalsProvider>
              <NotificationsProvider>
                <RoutesContext.Provider value={routes}>
                  <UserDataContext.Provider value={userData}>
                    <MainContainer routes={routes}/>
                  </UserDataContext.Provider>
                </RoutesContext.Provider>
              </NotificationsProvider>
            </ModalsProvider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen/>

        </QueryClientProvider>

      </MantineProvider>
  );
}

export default App;
