import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {bids, items} from "../api/API";
import {Box, Button, Group, NumberInput, Slider} from "@mantine/core";
import {useModals} from "@mantine/modals";
import {CurrencyEuro} from "tabler-icons-react";
import {useForm} from "@mantine/form";

const BidCreateRoute = (props) => {

  let {itemId} = useParams();

  const [amount, setAmount] = useState(0);

  const {data, isLoading} = items.single(itemId === undefined ? props.itemId : itemId)

  let navigate = useNavigate();

  const {mutate: createBid} = bids.create()

  const modals = useModals();


  const form = useForm({
    initialValues: {
      amount: 0
    },

    validate: {
      amount: (value) => (value < 1) ? 'Betrag zu niedrig!' : (value <= data.remaining) ? null : 'Betrag größer als verbleibender Betrag!',
    },
  });



  if (isLoading) {
    return (<div>loading...</div>)
  }

  return (
      <Box sx={{ maxWidth: 300 }} mx="auto">
        <form onSubmit={form.onSubmit((values) => {
                createBid({itemId : data.id,amount:values.amount}, {
                  onSuccess: (data) => {
                    // message.info("created new book: " + values.title)
                    // props.afterSubmit(data.data);
                    console.log("successfully created")
                    // navigate(`/item/${itemId}`, {replace: true})
                    modals.closeAll()
                    window.location.reload()
                  },
                  onError: (error, variables) => {
                    alert(
                        `${JSON.stringify(
                            error)} error while trying to create bid... maybe retry? -> ${error} with values : ${JSON.stringify(
                            variables)}`)
                  }
                })



        })}>



          <NumberInput
              required
              label="Anteil in €"
              min={1}
              max={data.remaining}
              icon={<CurrencyEuro/>}
              className={"pb-1"}

              {...form.getInputProps('amount')}
          />
          <Slider
              marks={[
                { value: 1, label: '1€' },
                // { value: 25, label: 'sm' },
                // { value: 50, label: 'md' },
                // { value: 75, label: 'lg' },
                { value: Math.trunc(data.remaining/3), label: `${Math.trunc(data.remaining/3)}€` },
                { value: Math.trunc((data.remaining/3)*2), label: `${(Math.trunc(data.remaining/3)*2)}€` },
                { value: data.remaining, label: `${data.remaining}€` },
              ]}
              defaultValue={1}
              min={1}
              max={data.remaining}
              label={(value) => value.toFixed(0)+ "€"}
              step={1}
              // styles={{markLabel: {display: 'none'}}}
              // value={amount}
              // onChange={setAmount}
              {...form.getInputProps('amount')}
          />

          <Group position="right" mt="md" className={"pt-4"}>
            <Button type="submit" color={"green"}>Eintragen</Button>
          </Group>
        </form>
      </Box>
      // <div className={" p-4  m-4"}>
      //   {/*Bid Create for {data.name}*/}
      //
      //   <form onSubmit={(event) => {
      //     event.preventDefault()
      //     console.log(event.target.amount.value)
      //
      //     if (event.target.amount.value < 1 ){
      //       alert('too low')
      //     }else {
      //
      //
      //     if (event.target.amount.value > data.remaining){
      //       alert('too high')
      //       event.target.amount.value = data.remaining
      //     } else {
      //       createBid({itemId : data.id,amount:event.target.amount.value}, {
      //         onSuccess: (data) => {
      //           // message.info("created new book: " + values.title)
      //           // props.afterSubmit(data.data);
      //           console.log("successfully created")
      //           // navigate(`/item/${itemId}`, {replace: true})
      //           modals.closeAll()
      //           window.location.reload('false')
      //         },
      //         onError: (error, variables) => {
      //           alert(
      //               `${JSON.stringify(
      //                   error)} error while trying to create bid... maybe retry? -> ${error} with values : ${JSON.stringify(
      //                   variables)}`)
      //         }
      //       })
      //     }
      //
      //     }
      //
      //   }}>
      //     <Group direction={"column"}>
      //       <div className={"w-full"}><Slider
      //           defaultValue={1}
      //           min={1}
      //           max={data.remaining}
      //           label={(value) => value.toFixed(0)+ "€"}
      //           step={1}
      //           styles={{markLabel: {display: 'none'}}}
      //           value={amount}
      //           onChange={setAmount}
      //       /></div>
      //
      //       <div className={"w-1/2"}><NumberInput value={amount} onChange={setAmount} label={"Anteil in €"}
      //                         min={1}
      //                         max={data.remaining}
      //                         icon={<CurrencyEuro/>}
      //           // rightSection={<div>€</div>}
      //           //  hideControls
      //
      //       /></div>
      //
      //       <input type="number" step={"1"} name={"amount"} value={amount} onChange={(val) => {setAmount(val.target.value)}}/>
      //       {/*<button className={"bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"}>create bid</button>*/}
      //
      //       <Button fullWidth color={'blue'} type={"submit"}>Eintragen</Button>
      //
      //
      //     </Group>
      //
      //   </form>
      //
      // </div>
  );
};

BidCreateRoute.propTypes = {};

export default BidCreateRoute;