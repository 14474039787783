import React, {useState} from "react";
import PropTypes from 'prop-types'
import {useNavigate} from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Group,
  Image,
  LoadingOverlay,
  NumberInput,
  Text,
  TextInput,
  useMantineTheme
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {Photo, X} from "tabler-icons-react";
import axios from "axios";
import {showNotification} from "@mantine/notifications";
import {RichTextEditor} from "@mantine/rte";
import {Dropzone, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import {basePath} from "../utils";

const ItemForm = (props) => {
  let navigate = useNavigate();
  const theme = useMantineTheme();

  const [posting, setPosting] = useState(false);

  const form = useForm({
    initialValues: {
      name: props.name,
      description: props.description,
      price: props.price,
      pictures: props.pictures,
      public: props.public
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  function getIconColor(status, theme) {
    return status.accepted
        ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
        : status.rejected
            ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
            : theme.colorScheme === 'dark'
                ? theme.colors.dark[0]
                : theme.colors.gray[7];
  }

  const dropzoneChildren = (status, theme) => (
      <Group position="center" spacing="xl" style={{minHeight: 100, pointerEvents: 'none'}}>


        <Photo style={{color: getIconColor(status, theme)}} size={80}/>

        <div>
          <Text size="xl" inline>
            Drag images here or click to select files
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            Attach as many files as you like, each file should not exceed 5mb
          </Text>
        </div>
      </Group>
  );

  const removePicture = (file) => {

    form.setFieldValue('pictures', form.values.pictures.filter((pic) => pic.path !== file.path))

  }

  const upload = (values) => {

    setPosting(true)

    let test = new FormData()

    // test.append("pictures", values.pictures)
    test.append("name", values.name)
    test.append("description", values.description)
    test.append("price", values.price)

    if (props.id !== -1) {

      // update

      axios.putForm(`${basePath}/items/${props.id}`, test, {withCredentials: true}).then(
          r => {
            console.log(r)
            navigate("/item", {replace: true})
          }).catch(reason => {

            showNotification({
              title: 'Fehler beim erstellen!',
              message: reason.toString(),
              color: "red"
            })
            setPosting(false)
          }
      )

    } else {
      // insert

      values.pictures.forEach((file) => test.append("pictures", file))
      axios.postForm(`${basePath}/items/`, test, {withCredentials: true}).then(
          r => {
            console.log(r)
            navigate("/item", {replace: true})
          }).catch(reason => {

            showNotification({
              title: 'Fehler beim erstellen!',
              message: reason.toString(),
              color: "red"
            })
            setPosting(false)
          }
      )

    }

    // axios.post(`http://localhost:7000/items/`, values, {withCredentials: true})
  }

  // You can get form values type using typeof to store function separately
  // const handleSubmit = (values) => console.log(values);

  return (
      <div className={"pt-16 h-full bg-slate-100 overflow-x-hidden overflow-y-auto"}>
        <Box sx={{maxWidth: 900}} mx="auto">

          <LoadingOverlay visible={posting} transitionDuration={500}/>

          <form onSubmit={form.onSubmit((values) => {
            upload(values)
          })}>
            <TextInput
                required
                label="Name"
                placeholder="item name"
                {...form.getInputProps('name')}
            />


            <RichTextEditor style={{minHeight: '600px'}} required label={"test"}  {...form.getInputProps(
                'description')}/>

            <NumberInput
                decimalSeparator=","
                label="price"
                // defaultValue={0.5}
                precision={2}
                step={0.01}
                required
                {...form.getInputProps('price')}
            />

            <Dropzone
                onDrop={(files) => {



                  form.setFieldValue('pictures', [...form.values.pictures , ...files]);
                }}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={3 * 1024 ** 2}
                accept={IMAGE_MIME_TYPE}
            >
              {(status) => dropzoneChildren(status, theme)}
            </Dropzone>


            <Group>
              {form.values.pictures.map((file) => {

                let element
                if (file.path === undefined) {
                  element =  <Image className={"cursor-pointer hover:bg-slate-400"} width={200} height={100}
                                    src={`${basePath}/upload/${file.id}`} fit={"contain"}/>
                } else {
                  element = <Image className={"cursor-pointer hover:bg-slate-400"} width={200} height={100}
                         src={URL.createObjectURL(file)} fit={"contain"}/>
                }

                return <>
                  {element}

                  <X className={"cursor-pointer hover:text-red-600"} onClick={() => {
                    removePicture(file)
                  }}/>

                </>
              })}
            </Group>


            <Checkbox
                mt="md"
                label="public visible"
                {...form.getInputProps('public', {type: 'checkbox'})}
            />

            <Group position="right" mt="md">
              <Button type="submit" disabled={posting}>Submit</Button>
            </Group>
          </form>
        </Box>
      </div>
  );
};

ItemForm.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  pictures: PropTypes.array,
  public: PropTypes.bool
};
ItemForm.defaultProps = {
  id: -1,
  name: '',
  description: '',
  price: 0,
  pictures: [],
  public: true
}

export default ItemForm;