import React from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {Badge, Button, Card, Group, Image, Text} from "@mantine/core";
import {useCookieState} from "ahooks";

const DefaultRoute = (props) => {
  let navigate = useNavigate();

  // const [token, setToken] = useCookieState('token');

  // const tryFetch = () => {
  //   axios.get(`http://localhost:7000/item/`, {withCredentials: true}).then(r => {
  //     console.log(r)
  //     navigate("/",{replace:true})
  //   }).catch(reason => {
  //     console.log(reason)
  //     navigate("/",{replace:true})
  //   })
  // }
  //
  // const logout = () => {
  //   axios.get(`http://localhost:7000/logout/`, {withCredentials: true}).then(r => {
  //     console.log(r)
  //     navigate("/",{replace:true})
  //     setToken(undefined)
  //   }).catch(reason => {
  //     console.log(reason)
  //     navigate("/",{replace:true})
  //     setToken(undefined)
  //   })
  // }


  return (
      <div className={"flex flex-row flex-wrap bg-slate-100 h-full"}>


          <Card shadow="sm" padding="lg" radius="md" withBorder className={"max-w-sm m-auto"}>
              <Card.Section>
                  <Image
                      src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
                      height={160}
                      alt="Norway"
                  />
              </Card.Section>

              <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>Lottis Wunschliste</Text>
                  <Badge color="pink" variant="light">
                      aktiv
                  </Badge>
              </Group>

              <Text size="sm" color="dimmed">
                  Lottis Wunschliste zur Einschulung! bla bla bla bla bla bla bla bla bla bla bla bla bla bla
              </Text>

              <Button variant="light" color="blue" fullWidth mt="md" radius="md" onClick={()=>{
                    navigate("/item")
              }}>
                  zur Wunschliste
              </Button>
          </Card>

        {/*<div className={"basis-full flex"}>Default route</div>*/}
        {/*<div className={"basis-full flex"}>*/}

        {/*  /!*<button className={"m-2 p-2 bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded"}*!/*/}
        {/*  /!*        onClick={tryFetch}>try fetch*!/*/}
        {/*  /!*</button>*!/*/}

        {/*  /!*<Button onClick={logout}>Logout</Button>*!/*/}

        {/*  Hier sollte eine Erklärung für das ganze stehen!*/}


        {/*</div>*/}
        {/*<div className={"basis-full m-4 flex"}>*/}
        {/*  <Link className={"bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"} to="item">to*/}
        {/*    list</Link>*/}
        {/*</div>*/}
        {/*<div className={"basis-full m-4 flex"}>*/}
        {/*  <Link className={"bg-slate-500 hover:bg-slate-400 active:bg-slate-200 rounded p-2 m-2"} to="item/create">create new item</Link>*/}
        {/*</div>*/}
      </div>
  );
};

DefaultRoute.propTypes = {};

export default DefaultRoute;