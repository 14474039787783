import DefaultRoute from "./DefaultRoute";
import LoginRoute from "./LoginRoute";
import ListRoute from "./ListRoute";
import ItemRoute from "./ItemRoute";
import BidCreateRoute from "./BidCreateRoute";
import CreateEntryRoute from "./CreateEntryRoute";
import BidListRoute from "./BidListRoute";
import ItemEditRoute from "./ItemEditRoute";

export const createRoutes = () => {
  return [
    {
      groupTitle: "",
      // groupIcon: <HomeOutlined/>,
      routes: [
        {
          path: "/",
          exact: true,
          component: <ListRoute/>,
          title: "home"
        },
        {
          path: "/login",
          component: <LoginRoute/>,
          title: "login"
        },
        {
          path: "/item",
          component: <ListRoute/>,
          title: "list",
          exact: true
        },
        {
          path: "/item/create",
          component: <CreateEntryRoute/>,
          title: "create new entry",
          exact: true
        },
        {
          path: "/item/:itemId",
          component: <ItemRoute/>,
          exact: true,
          title: "item"
        },
        {
          path: "/item/:itemId/edit",
          component: <ItemEditRoute/>,
          title: "edit bid"
        },
        {
          path: "/me",
          component: <DefaultRoute/>,
          title: "ME"
        },
        {
          path: "/profile",
          component: <DefaultRoute/>,
          title: "profile"
        },
        {
          path: "/dash",
          component: <DefaultRoute/>,
          title: "dashboard"
        },
        {
          path: "/admin",
          component: <DefaultRoute/>,
          title: "admin"
        },
        {
          path: "/bid",
          component: <BidListRoute/>,
          title: "admin"
        },
      ]
    },
    // {
    //   groupTitle: "Books",
    //   // groupIcon: <ShoppingCartOutlined/>,
    //   routes: [
    //     {
    //       path: "/book",
    //       exact: true,
    //       component: <BookListRoute/>,
    //       title: "books"
    //     },
    //     {
    //       path: "/book/create",
    //       exact: true,
    //       component: <BookCreateRoute/>,
    //       title: "create book"
    //     },
    //     {
    //       path: "/book/:bookId",
    //       component: <SingleBookRoute/>,
    //       title: "single book",
    //       ignore: true,
    //       exact: true
    //     },
    //     {
    //       path: "/book/:bookId/edit",
    //       component: <BookEditRoute/>,
    //       title: "single book edit",
    //       ignore: true
    //     },
    //   ]
    // },
    // {
    //   groupTitle: "Users",
    //   // groupIcon: <ShoppingCartOutlined/>,
    //   routes: [
    //     {
    //       path: "/user",
    //       exact: true,
    //       component: <UserListRoute/>,
    //       title: "users"
    //     },
    //     {
    //       path: "/user/create",
    //       exact: true,
    //       component: <DefaultRoute/>,
    //       title: "create user"
    //     },
    //     {
    //       path: "/user/:userId",
    //       component: <DefaultRoute/>,
    //       title: "single user",
    //       ignore: true,
    //       exact: true
    //     },
    //     {
    //       path: "/user/:userId/edit",
    //       component: <DefaultRoute/>,
    //       title: "single user edit",
    //       ignore: true
    //     },
    //   ]
    // },
  ];
};