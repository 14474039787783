import React, {useContext} from "react";
import {Route, Routes} from "react-router-dom";

const Container = (props) => {
  return (
      <>
        <Routes>
          {props.routes.map(x => x.routes)
          .reduce((previousValue, currentValue) => previousValue.concat(currentValue))
          .map(route => {
                const {component, ...adjustedRoute} = route;
                return <Route key={route.path} {...adjustedRoute} element={route.component}/>
              }
          )}
        </Routes>
      </>
  );
};

Container.propTypes = {};

export default Container;