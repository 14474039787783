import React, {useState} from "react";
import PropTypes from 'prop-types'
import {useForm} from "@mantine/form";
import {
  Box,
  Button,
  Checkbox,
  Group, Image,
  Loader, LoadingOverlay,
  NumberInput,
  Overlay,
  Text,
  TextInput,
  useMantineTheme
} from "@mantine/core";
import {RichTextEditor} from "@mantine/rte";
import {Dropzone, IMAGE_MIME_TYPE} from "@mantine/dropzone";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {showNotification} from "@mantine/notifications";
import {Cross, Photo, Upload, X} from "tabler-icons-react";
import ItemForm from "../components/ItemForm";

const CreateEntryRoute = (props) => {
  return (
     <ItemForm/>
  );
};

CreateEntryRoute.propTypes = {};

export default CreateEntryRoute;