import React from "react";
import PropTypes from 'prop-types'
import {Link, useNavigate} from "react-router-dom";
import {Badge, Button, Card, Group, Image, RingProgress, Text, useMantineTheme} from "@mantine/core";
import {RichTextEditor} from "@mantine/rte";
import {Check} from "tabler-icons-react";
import {basePath} from "../utils";

const ItemListContainer = (props) => {
  const theme = useMantineTheme();

  let navigate = useNavigate();

  const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

  let element = ''
  if (props.pictures !== undefined && props.pictures.length > 0) {
    element = `${basePath}/upload/${props.pictures[0].id}`
  }

  let filled = false
  if(props.remaining === 0 ){
    filled = true
  }

  let grayscale = ""
  if (filled) {
    grayscale = " filter grayscale "
  }


  return (

      <Card shadow="sm" p="lg" radius={"md"} className={"hover:shadow-xl lg:hover:scale-105 transition-all cursor-pointer basis-full md:basis-5/12 2xl:basis-3/12 flex-grow"} onClick={() => {
        navigate(`/item/${props.id}`)
      }}>
        <Card.Section>
          <Image src={element} height={320} className={grayscale} alt=""
                 classNames={{

                   image: '',
                 }}

                 withPlaceholder/>
        </Card.Section>

        <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
          <Text size={"xl"} weight={500}>{props.name}</Text>
          <Group>
            {!filled && <Group>
              <Badge color={filled ? "gray" : "green"} size="xl">
                {props.remaining}€
              </Badge>
            </Group>}
            <RingProgress
                size={80}
                sections={[{value: 100-(props.remaining/props.price * 100), color: 'green'}]}
                className={""}
                label={
              // filled && <Check size={12} />
                  <Text color="blue" weight={700} align="center" size={"xs"}>
                    {Math.trunc(100-(props.remaining/props.price * 100))}%
                  </Text>
                }
            /></Group>
        </Group>

        <RichTextEditor className={"overflow-auto  max-h-52 "} readOnly value={props.description}  onChange={()=>{}}/>
      </Card>
  );
};

ItemListContainer.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  remaining: PropTypes.number.isRequired,
};

export default React.memo(ItemListContainer);