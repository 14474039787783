import React, {useContext} from "react";
import PropTypes from 'prop-types'
import {Trash} from "tabler-icons-react";
import {Anchor, Badge, Group, Highlight, Paper, Text, ThemeIcon} from "@mantine/core";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {useForceUpdate} from "@mantine/hooks";
import {useModals} from "@mantine/modals";
import {items} from "../api/API";
import {UserDataContext} from "../App";
import {basePath} from "../utils";

const BidContainer = (props) => {

  // const {data, isLoading} = items.single(props.itemID)
  const userData = useContext(UserDataContext)
  let test = `Soll dein Anteil von ${props.amount}€ an ${props.itemName} gelöscht werden?`


  const modals = useModals();
  const openDeleteModal = () =>
      modals.openConfirmModal({
        title: 'Anteil löschen',
        centered: true,
        children: (
            // <Highlight highlight={['an', `gelöscht`]} size="sm">
            //   Soll dein Anteil von {props.amount}€ an {props.itemName} gelöscht werden?
            // </Highlight>
            <Highlight highlight={[`${props.amount}€`, `${props.itemName}`]}

                       highlightStyles={(theme) => ({
                         backgroundImage: theme.fn.linearGradient(45, theme.colors.cyan[5], theme.colors.indigo[5]),
                         fontWeight: 1000,
                         WebkitBackgroundClip: 'text',
                         WebkitTextFillColor: 'transparent',
                       })}
            size={"md"}
            >{test}</Highlight>
        ),
        labels: { confirm: 'Anteil löschen!', cancel: "Nein, nicht löschen!" },
        confirmProps: { color: 'red' },
        trapFocus:false,
        closeOnClickOutside: true,
        overlayBlur:2,
        onCancel: () => console.log('Cancel'),
        onConfirm: () => deleteBid(),
      });



  const deleteBid = () => {
    axios.delete(`${basePath}/bids/${props.id}`, {withCredentials: true}).then(
        r => {
          window.location.reload()
        }

    )
  }

  return (

      <div key={props.id} className={"transition-all w-full flex rounded-lg shadow-sm transition-all hover:shadow-md"}>

        <div className={"rounded-l-lg basis-1/6 bg-green-100 flex justify-center items-center align-middle"}>

          <div className={"flex gap-1 basis-full justify-center"}>

            <Badge size="xl" variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}> {props.amount} €</Badge>

            {/*<div className={"basis-1/2 flex justify-center"}><ThemeIcon color="teal" size={24} radius="xl" >*/}
            {/*  <CircleCheck size={16}/>*/}
            {/*</ThemeIcon></div>*/}

            {/*<div className={"basis-1/2 grow"}>*/}
            {/*  {bid.amount} €*/}
            {/*</div>*/}

          </div>



        </div>

        <Paper  p={"lg"} radius={0} className={"w-full"}>
          <Group>
            <Group>{userData.admin && <div>{props.userName} : </div>} Anteil von {props.amount} € am Geschenk :<Anchor component={Link} to={`/item/${props.itemID}`}>{props.itemName}</Anchor></Group>
            {/*<Trash size={20} className={"cursor-pointer hover:text-red-600  animate transition-all hover:scale-125 "} onClick={openDeleteModal} />*/}
          </Group>

        </Paper>
        <div className={"group transition-all rounded-r-lg basis-1/12 hover:basis-1/4 md:hover:basis-1/6  bg-red-100 flex justify-center items-center align-middle cursor-pointer"} onClick={openDeleteModal}>

          <ThemeIcon color="red" size={24} radius="xl" className={"group-hover:scale-150 transition-all"}>
            <Trash size={16} />
          </ThemeIcon>

        </div>


      </div>


  );
};

BidContainer.propTypes = {
  standalone: PropTypes.bool,
  id: PropTypes.number,
  amount : PropTypes.number,
  itemID : PropTypes.number,
  userId: PropTypes.number,
  itemName: PropTypes.string,
  userName: PropTypes.string
};

export default BidContainer;