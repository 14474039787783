// noinspection JSIgnoredPromiseFromCall

import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {basePath} from "../utils";

function eaf() {
}

const useItems = () => useQuery('items',
    async () => (await axios.get(`${basePath}/items/`, {withCredentials: true})).data)

const fetchSingleItem = async (id) => {
  const {data} = await axios.get(`${basePath}/items/${id}`, {withCredentials: true});
  return data;
}
const useSingleItem = (id) => useQuery(['items', id], () => fetchSingleItem(id))

// const updateItem = ({
//   id, amount
// }) => axios.put(`http://localhost:7000/books/${id}`,
//     {
//       available,
//       title,
//       author,
//       theme,
//       description,
//       target_group,
//       annotation
//     }, {withCredentials: true})
//
// const useUpdateBook = () => {
//   const cache = useQueryClient()
//   return useMutation(updateBook, {
//     onSuccess: () => {
//       cache.invalidateQueries('books').then(eaf)
//     },
//   })
// }
//
// const createBook = ({
//   available,
//   title,
//   author,
//   theme,
//   description,
//   target_group,
//   annotation
// }) =>
//     axios.post(`http://localhost:7000/books/`,
//         {
//           available,
//           title,
//           author,
//           theme,
//           description,
//           target_group,
//           annotation
//         }, {withCredentials: true})
//
// const useCreateBook = () => {
//   const cache = useQueryClient()
//   return useMutation(createBook, {
//     onSuccess: () => {
//       cache.invalidateQueries('books').then(eaf)
//     },
//   })
// }
//
// const deleteBook = (id) => axios.delete(`http://localhost:7000/books/${id}`, {withCredentials: true})
// const useDeleteBook = () => {
//   const cache = useQueryClient()
//   return useMutation(deleteBook, {
//     // to prevent unnecessary refetching, invalidate cache only after the external onSuccess handler was executed
//     onSettled: (error) => {
//       if (error !== undefined) {
//         cache.invalidateQueries('books').then(eaf)
//       }
//     }
//   })
// }

export const items = {
  use: useItems,
  single: useSingleItem,
  // create: useCreateBook,
  // update: useUpdateBook,
  // delete: useDeleteBook
}

const useBids = () => useQuery('bids',
    async () => (await axios.get(`${basePath}/bids/`, {withCredentials: true})).data)

const fetchSingleBid = async (id) => {
  const {data} = await axios.get(`${basePath}/bids/${id}`, {withCredentials: true});
  return data;
}
const useSingleBid = (id) => useQuery(['bids', id], () => fetchSingleBid(id))

const updateBid = ({id, amount}) => axios.put(`${basePath}/bids/${id}`, {amount}, {withCredentials: true})

const useUpdateBid = () => {
  const cache = useQueryClient()
  return useMutation(updateBid, {
    onSuccess: () => {
      cache.invalidateQueries('bids')
    },
  })
}

const createBid = ({itemId, amount}) =>
    axios.post(`${basePath}/bids/`, {itemId,amount}, {withCredentials: true})

const useCreateBid = () => {
  const cache = useQueryClient()
  return useMutation(createBid, {
    onSuccess: () => {
      cache.invalidateQueries('bids')
    },
  })
}

const deleteBid = (id) => axios.delete(`${basePath}/bids/${id}`, {withCredentials: true})
const useDeleteBid = () => {
  const cache = useQueryClient()
  return useMutation(deleteBid, {
    // to prevent unnecessary refetching, invalidate cache only after the external onSuccess handler was executed
    onSettled: (error) => {
      if (error !== undefined) {
        cache.invalidateQueries('bids')
      }
    }
  })
}

export const bids = {
  use: useBids,
  single: useSingleBid,
  create: useCreateBid,
  update: useUpdateBid,
  delete: useDeleteBid,
}