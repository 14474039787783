import React, {useState} from "react";
import {items} from "../api/API";
import ItemListContainer from "../components/ItemListContainer";
import {Switch} from "@mantine/core";

const ListRoute = (props) => {

    const [checked, setChecked] = useState(false);

    const {isLoading, data} = items.use();

    if (isLoading) {
        return (
            <div>loading</div>
        )
    }

    return (
        <div className={"h-full bg-slate-100 overflow-x-hidden overflow-y-auto"}>

            <Switch label={"Erfüllte Wünsche anzeigen"} checked={checked}
                    onChange={(event) => setChecked(event.currentTarget.checked)}/>


            <div className={"flex flex-row justify-left flex-wrap lg:p-16 gap-8"}>
                {data.filter((item) => checked || item.remaining > 0).map((item) => {
                    return (
                        <ItemListContainer key={item.id} id={item.id} name={item.name}
                                           description={item.description} price={item.price}
                                           remaining={item.remaining}
                                           pictures={item.pictures}/>
                    )
                })}
            </div>

            {/*<Grid gutter={40} className={"p-4"}>*/}


            {/*  {data.filter((item)=> checked || item.remaining > 0).map((item) => {*/}
            {/*    return (*/}
            {/*        <Grid.Col key={item.id} sm={12} md={6} lg={3}> <ItemListContainer key={item.id} id={item.id} name={item.name}*/}
            {/*                                                     description={item.description} price={item.price}*/}
            {/*                                                     remaining={item.remaining}*/}
            {/*                                                     pictures={item.pictures}/></Grid.Col>*/}
            {/*    )*/}
            {/*  })}*/}


            {/*</Grid>*/}


        </div>
    );
};

ListRoute.propTypes = {};

export default ListRoute;